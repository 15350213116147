<template>
  <div>
    <NuxtPageDependency>
      <PageHeader
        @menu:open="menuOpen"
        @menu:close:start="menuCloseStart"
        @menu:close:finished="menuCloseFinish"
      />
    </NuxtPageDependency>

    <div
      :class="{
        'pt-[100px]': (isMenuOpen || !hasMenuFinishedClosing) && isLessThanLg,
      }"
    >
      <NuxtPageDependency>
        <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
      </NuxtPageDependency>

      <div>
        <ClientOnly>
          <DrupalMessages v-if="!isEditing" />
        </ClientOnly>

        <slot />
      </div>
    </div>

    <NuxtPageDependency>
      <PageFooter :business-area-key="businessAreaKey" />
    </NuxtPageDependency>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const breadcrumb = useBreadcrumbLinks()
const businessAreaKey = useBusinessArea()
const { isLessThanLg } = useViewport()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const showBreadcrumb = computed(
  () =>
    route.meta.hideBreadcrumb !== true && route.meta.name !== 'drupal-route',
)

const isMenuOpen = ref(false)
const hasMenuFinishedClosing = ref(true)

useHead({
  htmlAttrs: {
    lang: language.value,
    class: computed(() => {
      if (businessAreaKey.value === 'SV_HOTEL') return 'is-hotel'
      return ''
    }),
  },
  bodyAttrs: {
    class: computed(() => {
      if (isMenuOpen.value) return 'overflow-y-hidden'
      return ''
    }),
  },
  link: [
    {
      rel: 'apple-touch-icon',
      href: `/favicons/sv/apple-touch-icon.png`,
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: `/favicons/sv/favicon.ico`,
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: `/favicons/sv/favicon.svg`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/sv/favicon-96x96.png`,
    },
    { rel: 'manifest', href: `/favicons/sv/site.webmanifest` },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/sv/web-app-manifest-192x192.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/sv/web-app-manifest-512x512.png`,
    },
    {
      rel: 'mask-icon',
      href: `/favicons/sv/safari-pinned-tab.svg`,
      color: '#5bbad5',
    },
  ],
})

function menuOpen() {
  isMenuOpen.value = true
}

function menuCloseStart() {
  if (isLessThanLg.value) {
    isMenuOpen.value = false
    hasMenuFinishedClosing.value = false
  }
}

function menuCloseFinish() {
  if (isLessThanLg.value) {
    hasMenuFinishedClosing.value = true
  }
}
</script>
